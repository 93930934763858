/* Custom Style */

@import 'custom';

$background-color: #1e1f28;
// $green-color: #80C2EC;
// $green-color: #f07fab;
$green-color: #1dd86e;
$header-color: #a8a8a8;
$lhs-menu-color: #202127;
$text-background-color: #2c2d36;
$text-color: #9e9e9e;
/* color: #636266; */
$text-font-size: 0.8em;
$small-text-font-size: 0.7em;
$smaller-text-font-size: 0.6em;

html,
.App {
  background: $background-color;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-items: stretch;
  align-content: stretch;
  height: 100%;
  font-family: 'Open Sans', sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.App {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
  flex-flow: column;

  @include media-breakpoint-up(lg) {
    padding-left: 0;
  }
}

.App::-webkit-scrollbar {
  display: none;
}

#home,
#resume {
  min-height: 100vh;
  height: 100%;
}

.welcome-message h2 span,
#experience .job .duration,
#education h6,
#tech .icon {
  color: $green-color;
}

#resume #description,
#experience .job,
#experience ul .list-group-item {
  background: $text-background-color;
}

#resume,
#LHSMenu .btn-link,
footer .icon,
footer .rotate {
  color: $text-color;
}

#home {
  height: 100vh;

  .profilePicture {
    background-image: url('./me3.jpg');
    background-size: cover;
  }

  @include media-breakpoint-up(lg) {
    align-items: flex-start;
    overflow-y: hidden;
  }

  @include media-breakpoint-down(md) {
    padding: 0;

    .profilePicture {
      height: 70%;
    }
  }

  header #nav-wrap {
    letter-spacing: 1.5px;

    @include media-breakpoint-down(md) {
      button {
        display: none;
      }
      #navbar {
        display: block;
      }
    }

    #navbar {
      @include media-breakpoint-up(lg) {
        margin-top: 40px;
      }
      #nav {
        font-weight: 600;

        @include media-breakpoint-up(lg) {
          font-size: 1.3em;
          margin-left: -80%;
        }

        a {
          @include media-breakpoint-down(lg) {
            font-size: 1.6em;
          }

          @include media-breakpoint-down(md) {
            text-align: center;
          }

          @include media-breakpoint-down(sm) {
            font-size: 1em;
            margin-top: -50px;
          }

          .down {
            transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
          }
        }
      }
    }
  }

  .welcome-message {
    letter-spacing: 1.5px;

    span {
      font-weight: 600;
    }

    h2 {
      color: $header-color;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
    }

    @include media-breakpoint-up(lg) {
      margin-top: 35%;
      align-self: center;
    }

    @media screen and (max-width: 1505px) {
      h2 {
        font-size: 20px;
        span {
          font-size: 43px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      position: relative;
    }

    @include media-breakpoint-down(sm) {
      text-align: center;

      h2 {
        font-size: 13px;
        margin-top: 0;

        span {
          font-size: 27px;
        }
      }
    }
  }
}

#resume {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  @include media-breakpoint-down(md) {
    .RHSmenu {
      margin-bottom: 30px;
    }
  }

  #description {
    margin: 0 auto;
    display: flex;

    img {
      width: 9%;
    }

    .text {
      font-size: $small-text-font-size;
      padding: 20px;
    }

    @include media-breakpoint-up(lg) {
      .text {
        font-weight: bold;
      }
    }

    @include media-breakpoint-down(md) {
      margin-top: 20px;

      .text {
        padding: 10px;
        text-align: justify;
      }

      img {
        width: 20%;
      }
    }

    @include media-breakpoint-down(sm) {
      img {
        display: none;
      }
    }
  }

  #LHSMenu {
    .LHScontainer {
      background: $lhs-menu-color;
      text-align: center;

      @include media-breakpoint-down(lg) {
        .btn {
          margin: 0 0 30px 0;
        }
      }
    }

    hr {
      width: 75%;
      border-color: $text-color;
    }

    #education {
      font-size: $text-font-size;
    }

    #languages {
      .outer {
        width: 20%;

        @include media-breakpoint-up(lg) {
          margin: 10px;
        }

        @include media-breakpoint-down(md) {
          width: 15%;
          p {
            font-size: $text-font-size;
          }
        }

        @include media-breakpoint-down(sm) {
          width: 40%;
          p {
            font-size: 1em;
          }
        }
      }

      .circular-chart {
        .base-circle {
          fill: none;
          stroke: black;
          stroke-width: 1.5;
        }

        .coloured-circle {
          fill: none;
          stroke-width: 1.5;
          stroke-linecap: round;
          stroke: $green-color;
        }

        .percentage {
          fill: white;
          font-size: 0.4em;
          text-anchor: middle;
        }
      }
    }
    #tech {
      .skill {
        font-size: $text-font-size;
        margin-left: 20px;
        padding-left: 20px;

        @media screen and (min-width: 990px) and (max-width: 1540px) {
          font-size: $small-text-font-size;
          margin-left: 0;
          padding-left: 0;
        }

        @include media-breakpoint-down(sm) {
          text-align: left;
          margin-left: 0;
          padding-left: 0;
        }
      }
    }
  }

  #experience {
    margin: 0 auto;
    display: flex;
    flex-flow: column wrap;

    h5 {
      font-weight: 700;
    }

    .job .duration {
      bottom: 1%;
    }

    ul {
      font-size: $small-text-font-size;
    }

    @media screen and (min-width: 990px) and (max-width: 1700px) {
      margin-top: 20px;
      ul {
        font-size: $smaller-text-font-size;
      }
    }

    @include media-breakpoint-up(lg) {
      .description,
      .experience-row {
        margin-bottom: 20px;
      }
    }

    @include media-breakpoint-down(md) {
      .row {
        margin-bottom: 25px;
      }
    }

    @include media-breakpoint-down(sm) {
      .row .col-md-6 {
        margin-bottom: 25px;
      }
      .experience-row,
      .row .last-job {
        margin-bottom: 0;
      }
    }
  }

  footer {
    > ul {
      height: 100vh;
    }

    .icon {
      font-size: 2rem;
    }

    .social {
      > div {
        margin: 0 auto;
      }

      @include media-breakpoint-down(md) {
        flex-direction: row;
      }

      @include media-breakpoint-up(lg) {
        margin-bottom: 20px;
      }
    }

    .list-group-item {
      background: none;
    }

    .rotate {
      font-size: large;
      transform: rotate(90deg);
      /* Legacy vendor prefixes that you probably don't need... */
      /* Safari */
      -webkit-transform: rotate(90deg);
      /* Firefox */
      -moz-transform: rotate(90deg);
      /* IE */
      -ms-transform: rotate(90deg);
      /* Opera */
      -o-transform: rotate(90deg);
      /* Internet Explorer */
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
    }

    @include media-breakpoint-down(md) {
      .homeLink {
        display: none;
      }
    }
  }
}
